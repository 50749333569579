<script lang="ts" setup>
import { ref } from "vue";
import { mdiArrowRight, mdiClose, mdiEmailOutline } from "@mdi/js";
import type { Employee } from "../../types/employee";
import IconInfo from "~/../_common/assets/icons/info.svg";

const { t } = useI18n();
const localeRoute = useLocaleRoute();

const props = defineProps<{
  name: string;
  showContact?: boolean;
  showExecutiveRole?: boolean;
  showPrimaryTeam?: boolean;
}>();

const employeeName = computed(() => props.name.split("/").pop() as string);
const path = computed(() => "/employees/" + employeeName.value);

//@ts-ignore
const route = localeRoute({
  name: "employees-name",
  params: { name: employeeName.value },
});

const isActive = ref(false);

const handleMouseEnter = () => {
  isActive.value = true;
};

const handleMouseLeave = () => {
  isActive.value = false;
};

const handleCloseClick = (event: Event) => {
  event.stopPropagation();
  isActive.value = false;
};

const clickCard = () => {
  isActive.value = true;
};
</script>

<template>
  <div
    class="employee-card position-relative rounded-lg"
    @mouseenter="handleMouseEnter"
    @click="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <ContentQuery :path="path" find="one">
      <template #default="{ data: employee }: { data: Employee }">
        <div :class="{ hover: isActive }" class="employee-card__inner">
          <v-card
            class="employee-card__content overflow-hidden"
            color="transparent"
            :elevation="0"
            @click="clickCard"
          >
            <!-- TODO: implement solution based on min-width/min-height -->
            <HwImage
              v-if="employee.image"
              :src="employee.image"
              class="employee-card__image"
              aspect-ratio="0.77"
              width="330px"
              height="480px"
            />

            <div class="employee-card__footer py-4">
              <v-row class="rounded-lg">
                <v-col cols="2" class="rounded-lg" />

                <v-col class="rounded-lg px-0">
                  {{ employee.prefixTitle }} {{ employee.firstName }}
                  {{ employee.lastName
                  }}<span v-if="employee.suffixTitle" class="hidden-md-and-down"
                    >, {{ employee.suffixTitle }}
                  </span>
                </v-col>

                <v-col cols="2" class="rounded-lg">
                  <IconInfo style="width: 1.3rem" />
                </v-col>
              </v-row>

              <v-divider
                v-if="props.showExecutiveRole || props.showPrimaryTeam"
                class="mx-auto my-2"
                inset
              />

              <div class="d-flex justify-center">
                <div v-if="employee.executiveRole && props.showExecutiveRole">
                  {{ employee.executiveRole }}
                </div>

                <v-divider
                  v-if="props.showExecutiveRole && props.showPrimaryTeam"
                  class="mx-3"
                  vertical
                />

                <div v-if="props.showPrimaryTeam">
                  {{ t(`teams.${employee.primaryTeam}.title`) }}
                </div>
              </div>
            </div>
          </v-card>

          <div class="employee-card__hover-content rounded-lg">
            <div class="slide-down-content">
              <v-row align="center" class="flex-grow-0">
                <v-col cols="2" />

                <v-col
                  class="text-center body-semibold mt-4 px-0"
                  style="font-weight: 600"
                >
                  {{ employee.prefixTitle }} {{ employee.firstName }}
                  {{ employee.lastName
                  }}<span v-if="employee.suffixTitle" class="hidden-md-and-down"
                    >, {{ employee.suffixTitle }}
                  </span>
                </v-col>

                <v-col cols="2" class="d-flex justify-end mr-4 mt-4">
                  <v-btn :icon="[mdiClose]" @click="handleCloseClick" />
                </v-col>
              </v-row>
            </div>
            <div class="d-flex flex-column h-100 justify-end">
              <hw-button
                underline
                class="mb-4 slide-up-content"
                :to="route"
                size="dense"
                :icon="[mdiArrowRight]"
              >
                {{ t("employees.profile") }}
              </hw-button>

              <hw-button
                v-if="showContact"
                underline
                :icon="[mdiEmailOutline]"
                :href="`mailto:${employee.contactDetails.eMail}`"
                size="dense"
                background="primary"
                class="mb-6 mx-6 slide-up-content"
              >
                {{ t("employees.contact") }}
              </hw-button>
            </div>
          </div>
        </div>
      </template>
      <template #not-found> not found </template>
    </ContentQuery>
  </div>
</template>

<style lang="scss">
@use "assets/scss/settings";
.employee-card {
  max-width: 330px;
  width: 100%;

  &__inner {
    position: relative;
  }

  &__content {
    height: 100%;

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(var(--v-theme-background), 0.5) !important;
      left: 0;
    }
  }

  &__hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__footer {
    text-align: center;
    background-color: rgba(var(--v-theme-background), 0.9);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
  }

  &__image img {
    --radius: 400px;
    --rotation: -90deg;
    clip-path: polygon(
      calc(50% + var(--radius) * cos(var(--rotation) + 0deg))
        calc(40% + var(--radius) * sin(var(--rotation) + 0deg)),
      calc(50% + var(--radius) * cos(var(--rotation) + 60deg))
        calc(40% + var(--radius) * sin(var(--rotation) + 60deg)),
      calc(50% + var(--radius) * cos(var(--rotation) + 120deg))
        calc(40% + var(--radius) * sin(var(--rotation) + 120deg)),
      calc(50% + var(--radius) * cos(var(--rotation) + 180deg))
        calc(40% + var(--radius) * sin(var(--rotation) + 180deg)),
      calc(50% + var(--radius) * cos(var(--rotation) + 240deg))
        calc(40% + var(--radius) * sin(var(--rotation) + 240deg)),
      calc(50% + var(--radius) * cos(var(--rotation) + 300deg))
        calc(40% + var(--radius) * sin(var(--rotation) + 300deg))
    );
    transition: clip-path 0.3s ease-in-out;
  }

  .slide-up-content {
    transform: translateY(100%);
    opacity: 0;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
  }

  .slide-down-content {
    background-color: rgba(var(--v-theme-background), 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    opacity: 1;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
  }

  &__inner.hover {
    .employee-card__footer {
      opacity: 0;
    }

    .employee-card__image img {
      --radius: 120px;
      --rotation: 0deg;
    }

    .slide-up-content {
      transform: translateY(0);
      opacity: 1;
    }

    .slide-down-content {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>
